








































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

import { FilterField, Filter } from '@/models/filters';

import TableFilterField from './filter-field.vue';

@Component({
  components: {
    TableFilterField,
  },
})
export default class TableFilter extends Vue {
  @PropSync('filters', { required: true }) syncedFilters!: Filter[];

  @Prop({ required: true }) filterFields!: FilterField[];

  filterTypeChanged(filter: Filter) {
    filter.value = '';
  }
}
