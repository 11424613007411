
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { Filter, FilterField } from '@/models/filters';

@Component
export default class FilterFieldDate extends Vue {
  @Prop({ required: true }) filter!: Filter;
  @Prop({ required: true }) field!: FilterField;

  DatePickerFrom = false;
  DatePickerTo = false;

  fromDate = '';
  toDate = '';

  async mounted() {
    if (this.filter && this.filter.value !== '') {
      if (this.filter.value.length == 20) {
        const filterValue = this.filter.value;
        this.fromDate = filterValue.substring(0, 10);
        this.toDate = filterValue.substring(10);
      }
    }
  }

  @Watch('field')
  fieldUpdate() {
    if (this.filter && this.filter.value !== '') {
      if (this.filter.value.length == 20) {
        const filterValue = this.filter.value;
        this.fromDate = filterValue.substring(0, 10);
        this.toDate = filterValue.substring(10);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateChanged() {
    if (this.fromDate !== '' && this.toDate !== '') {
      this.filter.value = this.fromDate + this.toDate;
    }
  }
}
