














import { Component, Vue, Prop } from 'vue-property-decorator';

import { Filter, FilterField } from '@/models/filters';
import FilterFieldDate from './filter-field-date.vue';
import FilterFieldEnum from './filter-field-enum.vue';
import FilterFieldString from './filter-field-string.vue';
import FilterFieldUser from './filter-field-user.vue';
import FilterFieldGroup from './filter-field-group.vue';
import FilterFieldDateRange from './filter-field-daterange.vue';

@Component
export default class TableFilterField extends Vue {
  @Prop({ required: true }) filter!: Filter;

  @Prop({ required: true }) filterFields!: FilterField[];

  get field() {
    return this.filterFields.find((x) => x.key === this.filter.key);
  }

  get component() {
    switch (this.field?.type) {
      case 'date':
        return FilterFieldDate;
      case 'enum':
        return FilterFieldEnum;
      case 'group':
        return FilterFieldGroup;
      case 'user':
        return FilterFieldUser;
      case 'dateRange':
        return FilterFieldDateRange;
      default:
        return FilterFieldString;
    }
  }
}
