



















import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import { Filter, FilterField } from '@/models/filters';
import UserService from '@/services/user-service';
import { User } from '@/models/case-maintenance.d';

@Component
export default class FilterFieldEnum extends Vue {
  @Inject() UserService!: UserService;
  @Prop({ required: true }) filter!: Filter;
  @Prop({ required: true }) field!: FilterField;

  users: User[] = [];

  async mounted() {
    const response = await this.UserService.listUsers({ includeHidden: false });

    this.users = response._embedded.users;
  }
}
