import { debounce, DebounceSettings } from "lodash";

export default function Debounce(period: number, options?: DebounceSettings) {
  return function(target: any, key: string, descriptor: PropertyDescriptor) {
    const fn = target[key];

    if (typeof fn !== 'function') return;

    const callableFn = fn as unknown as ((...args: any[]) => any);

    descriptor.value = debounce(callableFn, period, options) as any;
  }
}

